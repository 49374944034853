import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import River from '../../../assets/img/portfolio/river.png'

const Project1Card = () => {
    return (
        <Container>
            <Row>
                <h3>Introduction</h3>
                <p>This project was conceived as a solution to bridge the gap between content creators and advertisers, providing creators with an alternative revenue stream through product placement. The platform aims to facilitate connections between creators and advertisers, enabling creators to monetize their content through strategic product placements. This report outlines the objectives, implementation, and outcomes of the project.</p>
            </Row>
            <Row>
                <Col>
                    <h3>Objectives</h3>
                    <ul>
                        <li>Connect content creators with advertisers to facilitate product placement opportunities.</li>
                        <li>Provide content creators with an additional revenue stream through brand partnerships.</li>
                        <li>Enhance brand awareness and boost sales for advertisers through strategic product placements in content.</li>
                    </ul>
                    <h3>Implementation</h3>
                    <ul>
                        <li><h4>Platform Development</h4></li>
                        <ul>
                            <li>Developed a web-based platform to serve as the intermediary between content creators and advertisers.</li>
                            <li>Implemented features allowing creators to create profiles and advertisers to browse and select creators for product placement opportunities.</li>
                            <li>Integrated a matching algorithm to pair creators with advertisers based on audience demographics, content genre, and brand preferences.</li>
                        </ul>
                        <li><h4>Product Placement Mechanism</h4></li>
                        <ul>
                            <li>Designed a system for creators to seamlessly incorporate advertisers' products into their content.</li>
                            <li>Established guidelines and procedures for product placement to ensure brand integration is natural and non-intrusive.</li>
                            <li>Implemented payment processing functionality to facilitate transactions between creators and advertisers.</li>
                        </ul>
                    </ul>
                    <h3>Outcomes</h3>
                    <ul>
                        <li><h4>Creator-Advertiser Connections</h4></li>
                        <ul>
                            <li>Successfully facilitated connections between numerous content creators and advertisers.</li>
                            <li>Provided creators with diverse product placement opportunities across various content genres and audience demographics.</li>
                        </ul>
                        <li><h4>Revenue Generation</h4></li>
                        <ul>
                            <li>Enabled content creators to generate revenue through product placement agreements with advertisers.</li>
                            <li>Enhanced monetization options for creators beyond traditional advertising models.</li>
                        </ul>
                        <li><h4>Brand Awareness and Sales Boost</h4></li>
                        <ul>
                            <li>Facilitated effective brand awareness campaigns for advertisers through strategic product placements in content.</li>
                            <li>Contributed to increased sales and brand visibility for advertisers by reaching targeted audience segments through content creators.</li>
                        </ul>
                    </ul>
                </Col>
                <Col>
                    <Card.Img src={River}/>
                    <p>Project Completed March 2024</p>
                </Col>
            </Row>
            <Row>
                <h3>Conclusion</h3>
                <p>The development and implementation of the content creator-advertiser platform have provided a valuable solution for both creators and advertisers. By facilitating meaningful connections and enabling innovative revenue streams, the platform has contributed to the growth and sustainability of content creation ecosystems. Moving forward, continued enhancements and expansions of the platform will further solidify its role as a pivotal tool in the evolving landscape of digital advertising and content monetization.</p>
            </Row>
        </Container>
    )
}

export default Project1Card;