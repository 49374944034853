import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import Wepark from '../../../assets/img/portfolio/wepark.png'

const Project6Card = () => {
    return (
        <Container>
            <Row>
                <h3>Introduction</h3>
                <p>During my early college days, I encountered constant frustration due to the lack of available and affordable parking options on or around the campus. Upon observing that many residents possessed street parking passes associated with their leases that often remained unused, an idea emerged: Why not enable individuals to rent out their unused parking spaces on a flexible, as-needed basis? To address this issue, I embarked on the development of a simple app designed to connect users in need of parking with individuals willing to rent out their unused parking spaces. This report details the objectives, methodology, and outcomes of the project.</p>
            </Row>
            <Row>
                <Col>
                    <h3>Objectives</h3>
                    <ul>
                        <li>Address the issue of limited parking availability and affordability by facilitating peer-to-peer parking rentals.</li>
                        <li>Provide a platform for individuals with unused parking spaces to rent them out on a flexible, as-needed basis.</li>
                        <li>Enhance convenience and accessibility for users seeking parking solutions in urban environments.</li>
                    </ul>
                    <h3>Implementation</h3>
                    <ul>
                        <li><h4>App Development</h4></li>
                        <ul>
                            <li>Developed a user-friendly mobile application that enables users to search for nearby parking spaces.</li>
                            <li>Implemented features allowing individuals with unused parking spaces to list their spots on the app and set rental prices per hour.</li>
                            <li>Integrated a secure payment processing system to facilitate transactions between parking space renters and users.</li>
                        </ul>
                    </ul>
                    <h3>Outcomes</h3>
                    <ul>
                        <li><h4>Enhanced Parking Accessiblility</h4></li>
                        <ul>
                            <li>Successfully developed a platform that improves access to parking by enabling individuals to rent out their unused parking spaces.</li>
                            <li>Provided users with a convenient and flexible solution for finding parking in urban areas, reducing the hassle and stress associated with parking.</li>
                        </ul>
                        <li><h4>Monetization Opportunities</h4></li>
                        <ul>
                            <li>Created opportunities for individuals to monetize their unused parking spaces by renting them out on a short-term basis.</li>
                            <li>Enabled users to earn extra income while contributing to a more efficient use of parking resources in urban environments.</li>
                        </ul>
                        <li><h4>Community Engagement</h4></li>
                        <ul>
                            <li>Fostered a sense of community by facilitating peer-to-peer interactions between parking space owners and users.</li>
                            <li>Contributed to a more collaborative and sharing-oriented approach to addressing parking challenges in urban areas.</li>
                        </ul>
                    </ul>
                </Col>
                <Col>
                    <Card.Img src={Wepark}/>
                    <p>Project Completed May 2019</p>
                </Col>
            </Row>
            <Row>
                <h3>Conclusion</h3>
                <p>The development of the peer-to-peer parking rental app has addressed the pressing issue of limited parking availability and affordability in urban environments. By providing a platform for individuals to rent out their unused parking spaces on a flexible, as-needed basis, the app has enhanced parking accessibility and convenience for users while creating monetization opportunities for parking space owners. Moving forward, continued refinement and expansion of the app will further solidify its role as a valuable tool for optimizing parking resources and improving urban mobility.</p>
            </Row>
        </Container>
    )
}

export default Project6Card;