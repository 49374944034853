import React from 'react'
import { Container, Image } from 'react-bootstrap';

import Headshot from '../../../../assets/img/headshot.png'

const Landing = () => {
    return (
        <header className='text-center text-white bg-primary pt-3'>
            <Container fluid className='pt-5'>
                <Image roundedCircle fluid className='d-block mx-auto mb-5' src={Headshot}/>
                <h1>Maxim Kapelina</h1>
                <hr className='star-light mb-0'/>
                <h2 className='font-weight-light mb-0 pb-2'>Web Developer - UX Designer - ML/AI Specialist</h2>
            </Container>
        </header>
    )
}

export default Landing;