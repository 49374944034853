import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import Coronavirus from '../../../assets/img/portfolio/coronavirus.png'

const Project4Card = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <h3>Introduction</h3>
                    <p>Amid the unprecedented challenges posed by the Coronavirus Pandemic, this project was initiated with the aim of exploring the potential of artificial intelligence (AI) to simulate the spread of a virus within a population. The project sought to broaden understanding of AI capabilities while also yielding meaningful insights into the efficacy of various viruses. By simulating a population and providing a generative AI model with the "DNA" of a virus, the project aimed to enable the AI to learn which genetic traits resulted in the most infectious and deadly virus. This report details the objectives, methodology, and outcomes of the project.</p>
                    <h3>Objectives</h3>
                    <ul>
                        <li>Investigate the feasibility of using AI to simulate the spread of a virus within a population.</li>
                        <li>Enhance understanding of AI capabilities through practical application in virus simulation.</li>
                        <li>Gain insights into the factors influencing the infectiousness and lethality of viruses through AI-driven analysis.</li>
                    </ul>
                    <h3>Implementation</h3>
                    <ul>
                        <li><h4>Population Simulation</h4></li>
                        <ul>
                            <li>Developed a simulated population environment to serve as the testing ground for virus propagation.</li>
                            <li>Implemented algorithms to model population dynamics, including interactions, mobility, and susceptibility to infection.</li>
                            <li>Designed parameters to simulate various demographic factors, such as age distribution, immunity levels, and geographical locations.</li>
                        </ul>
                        <li><h4>Generative AI Model</h4></li>
                        <ul>
                            <li>Provided a generative AI model with the genetic "DNA" of a virus, representing key traits and characteristics.</li>
                            <li>Utilized machine learning techniques to enable the AI model to learn and adapt based on simulated virus spread and outcomes.</li>
                            <li>Incorporated feedback mechanisms to iteratively refine the AI model's understanding of virus behavior and evolution.</li>
                        </ul>
                    </ul>
                    <h3>Outcomes</h3>
                    <ul>
                        <li><h4>AI-Driven Virus Simulation</h4></li>
                        <ul>
                            <li>Successfully simulated the spread of a virus within the population using the generative AI model.</li>
                            <li>Generated insights into the factors influencing virus transmission, including genetic traits, population dynamics, and environmental factors.</li>
                        </ul>
                        <li><h4>Identification of Viral Traits</h4></li>
                        <ul>
                            <li>Enabled the AI model to identify genetic traits associated with increased infectiousness and lethality.</li>
                            <li>Provided valuable data on the efficacy of various viruses under different scenarios, aiding in the understanding of virus behavior and evolution.</li>
                        </ul>
                        <li><h4>Expansion of AI Understanding</h4></li>
                        <ul>
                            <li>Enhanced understanding of AI capabilities through practical application in virus simulation.</li>
                            <li>Demonstrated the potential of AI to analyze complex systems and generate insights into real-world phenomena, such as virus spread and containment strategies.</li>
                        </ul>
                    </ul>
                </Col>
                <Col>
                    <Card.Img src={Coronavirus}/>
                    <p>Project Completed May 2021</p>
                </Col>
            </Row>
            <Row>
                <h3>Conclusion</h3>
                <p>The project has demonstrated the potential of artificial intelligence to simulate and analyze the spread of viruses within populations, offering valuable insights into virus behavior and evolution. By leveraging AI-driven analysis, the project has expanded understanding of both AI capabilities and the factors influencing the infectiousness and lethality of viruses. Continued refinement and application of AI-driven virus simulation techniques could contribute to enhancing preparedness and response strategies for future pandemics and public health emergencies.</p>
            </Row>
        </Container>
    )
}

export default Project4Card;