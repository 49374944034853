import './App.css';
import React from 'react'
import { Route, Routes } from 'react-router-dom';

import Main from './components/mainPage/main'
import Home from './components/mainPage/home/home'
import Portfolio from './components/portfolio/portfolio';
import Contact from './components/contact/contact';
import About from './components/about/about';

function App() {
    return (
        <Routes>
            <Route path='/' element={<Main />}>
                <Route index element={<Home />}/>
                <Route path='/portfolio' element={<Portfolio />}/>
                <Route path='/about' element={<About />}/>
                <Route path='/contact' element={<Contact />}/>
            </Route>
        </Routes>
    );
}

export default App;
