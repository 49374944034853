import React from 'react'

import { Container } from 'react-bootstrap'

const About = () => {
    const Background = "I am a motivated and curious individual driven by a passion for valuable knowledge and novel experiences. I was born in Washington, D.C. but grew up in Annapolis, MD. At the age of 8, I moved back to the D.C. area, spending my teenage years in Bethesda, MD at The Landon School for boys, from grade 3 to 12. From a young age, my curiosity was fostered through interests in hobbies such as reading, building, and even programming. Along the way, I’ve cultivated a strong sense of resiliency, adaptability, and creativity. These traits have become invaluable assets in both my personal and professional pursuits. With a steadfast commitment to growth and self-improvement, I approach each new opportunity with enthusiasm and a determination to make a positive impact."
    const Education = "After Graduating from The Landon School, I attended the University of Maryland. There, I focused on a degree in Computer Science, while also studying business in the Business Scholars two-year program. As a dedicated student, I immersed myself in the dynamic world of Computer Science, deciding to specialize in Machine Learning and Artificial Intelligence. Through rigorous coursework and hands-on projects, I gained a strong proficiency in cutting-edge technologies. Concurrently, my studies in business provided me with a strategic understanding of market dynamics, financial principles, and organizational behaviors. This laid the groundwork for a holistic approach to innovation and problem-solving."
    const Experience = "Throughout my professional journey, I've gained diverse experience across multiple technological domains, from application design and web development to full-stack development and machine learning. In these roles, I've leveraged my technical expertise to spearhead innovative projects and deliver cutting-edge solutions that meet the needs of clients and customers. This professional expertise has not only shaped my career journey but also placed me at the forefront of many modern technological innovations."
    const Activities = "Beyond the realm of my professional technological experience, I've also honed my leadership skills through my involvement in extracurricular activities during my time at the University of Maryland. As the president of the Water Polo club for two years, I successfully led a team of passionate individuals, fostering teamwork and camaraderie while overseeing the club's operations and events. Additionally, my year-long tenure as vice president of the Sigma Phi Delta Social/Professional fraternity equipped me with invaluable organizational and interpersonal skills, as I collaborated with fellow members to expand our brotherhood and foster a community of intelligent and driven individuals. These leadership skills have empowered me to make a meaningful contribution to any team I contribute to in any capacity."
    const SkillsAndInterests = "My skill set encompasses proficiency in a wide array of programming languages, including Java, Python, JavaScript, C, Ruby, and OCaml, along with expertise in frontend frameworks such as React, Angular, and Node.js. Additionally, I am adept at utilizing various technical tools and platforms, including MS Office, Git, GitHub, AWS, Azure, macOS, Linux, and Windows. My understanding extends beyond coding to encompass high-level technical concepts such as machine learning/artificial intelligence, cloud computing, networks, and database design. Beyond the realm of technology, I am deeply passionate about water polo, fitness, chess, piano, self-improvement seminars, and investing, which contribute to my holistic approach to personal and professional growth."

    return (
        <>
            <section className='bg-primary'>
                <Container>
                    <h1 className='text-uppercase text-center text-secondary'>About Me</h1>
                    <hr className='star-light mb-0'/>
                    <h4 className='text-center text-white'>Delve Into My Life, History, and Experience</h4>
                </Container>
            </section>
            <section>
                <Container>
                    <h2 className='text-uppercase text-center text-secondary'>Background</h2>
                    <p className='fs-4 text-secondary text-center'>{Background}</p>
                    <hr className='star-dark mb-0'/>
                </Container>
            </section>
            <section className='bg-primary'>
                <Container>
                    <h2 className='text-uppercase text-center text-secondary'>Education</h2>
                    <p className='fs-4 text-secondary text-center'>{Education}</p>
                    <hr className='star-light mb-0'/>
                </Container>
            </section>
            <section>
                <Container>
                    <h2 className='text-uppercase text-center text-secondary'>Experience</h2>
                    <p className='fs-4 text-secondary text-center'>{Experience}</p>
                    <hr className='star-dark mb-0'/>
                </Container>
            </section>
            <section className='bg-primary'>
                <Container>
                    <h2 className='text-uppercase text-center text-secondary'>Activities</h2>
                    <p className='fs-4 text-secondary text-center'>{Activities}</p>
                    <hr className='star-light mb-0'/>
                </Container>
            </section>
            <section>
                <Container>
                    <h2 className='text-uppercase text-center text-secondary'>Skills and Interests</h2>
                    <p className='fs-4 text-secondary text-center'>{SkillsAndInterests}</p>
                    <hr className='star-dark mb-0'/>
                </Container>
            </section>
        </>
    )
}

export default About;