import React from 'react'
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className='text-center footer'>
            <Container className='mb-3'>
                <Row>
                    <Col md={4} className='mb-5 mb-lg-0'>
                        <h4 className='text-uppercase'>Location</h4>
                        <p>3131 NE 7th Ave<br/>Miami, FL 33137</p>
                    </Col>
                    <Col md={4} className='mb-5 mb-lg-0'>
                        <h4 className='text-uppercase'>Around the Web</h4>
                        <ListGroup horizontal className='bg-transparent d-flex justify-content-center'>
                            <ListGroup.Item className='bg-transparent border-0'>
                                <a href='https://www.instagram.com/mkapelina' target='_blank' rel='noreferrer noopener'>
                                    <Button className='text-center btn-social rounded-circle'>
                                        <i className='fa fa-instagram fa-fw'/>
                                    </Button>
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item className='bg-transparent border-0'>
                                <a href='https://www.facebook.com/' target='_blank' rel='noreferrer noopener'>
                                    <Button className='text-center btn-social rounded-circle'>
                                        <i className='fa fa-facebook fa-fw'/>
                                    </Button>
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item className='bg-transparent border-0'>
                                <a href='https://www.linkedin.com/in/maxim-kapelina' target='_blank' rel='noreferrer noopener'>
                                    <Button className='text-center btn-social rounded-circle'>
                                        <i className='fa fa-linkedin fa-fw'/>
                                    </Button>
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item className='bg-transparent border-0'>
                                <a href='https://www.github.com/mkapelina' target='_blank' rel='noreferrer noopener'>
                                    <Button className='text-center btn-social rounded-circle'>
                                        <i className='fa fa-github fa-fw'/>
                                    </Button>
                                </a>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={4}>
                        <h4 className='text-uppercase mb-4'>Maxim Kapelina</h4>
                        <p className='lead mb-0'><span>Maximizing efficiency through leadership in Software Development</span></p>
                    </Col>
                </Row>
            </Container>
            <small>Copyright ©Maxim Kapelina 2024</small>
        </footer>
    )
}

export default Footer;