import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import Storyboard from '../../../assets/img/portfolio/storyboard.png'

const Project3Card = () => {
    return (
        <Container>
            <Row>
                <h3>Introduction</h3>
                <p>Motivated by the desire to streamline project collaboration and simplify the process of coordinating software development efforts, this project aimed to create a platform that facilitates collaborative storyboarding for technical projects. The platform also incorporated a marketplace feature to enable freelancers to bid on and complete project sections posted by users. This report outlines the objectives, implementation strategies, and outcomes of the project.</p>
            </Row>
            <Row>
                <Col>
                    <h3>Objectives</h3>
                    <ul>
                        <li>Develop a platform for collaborative storyboarding of technical projects to enhance project planning and coordination.</li>
                        <li>Provide users with a lightweight and user-friendly interface for effectively organizing and visualizing project requirements and tasks.</li>
                        <li>Integrate a marketplace feature to enable freelancers to bid on project sections posted by users, fostering a collaborative environment for project completion.</li>
                    </ul>
                    <h3>Implementation</h3>
                    <ul>
                        <li><h4>Platform Development</h4></li>
                        <ul>
                            <li>Created a user-friendly platform for collaborative storyboarding, allowing users to visually map out project requirements, tasks, and dependencies.</li>
                            <li>Implemented features for users to create project boards, add and organize project tasks, and collaborate with team members in real-time.</li>
                            <li>Developed a responsive and intuitive user interface to enhance usability and accessibility for users across various devices and platforms.</li>
                        </ul>
                        <li><h4>Marketplace Integration</h4></li>
                        <ul>
                            <li>Incorporated a marketplace feature into the platform, enabling users to post sections of their projects as tasks available for freelancers to bid on.</li>
                            <li>Implemented a bidding system that allows freelancers to submit proposals and bids for completing project sections posted by users.</li>
                            <li>Integrated payment processing functionality to facilitate secure transactions between users and freelancers upon successful completion of project sections.</li>
                        </ul>
                    </ul>
                    <h3>Outcomes</h3>
                    <ul>
                        <li><h4>Efficient Project Collaboration</h4></li>
                        <ul>
                            <li>Successfully developed a platform that streamlines project collaboration and enhances communication and coordination among team members.</li>
                            <li>Provided users with a centralized hub for organizing project requirements, tasks, and milestones, leading to improved project planning and execution.</li>
                        </ul>
                        <li><h4>Facilitated Freelancer Collaboration</h4></li>
                        <ul>
                            <li>Enabled freelancers to leverage the platform to bid on and complete project sections posted by users, expanding opportunities for collaboration and project completion.</li>
                            <li>Facilitated seamless interactions between users and freelancers, fostering a mutually beneficial environment for project outsourcing and task delegation.</li>
                        </ul>
                        <li><h4>Enhanced Lightweight Project Management</h4></li>
                        <ul>
                            <li>Offered users a lightweight and intuitive solution for project management, allowing for flexible adaptation to project requirements and evolving priorities.</li>
                            <li>Empowered users to efficiently storyboard their projects, visualize project progress, and adapt to changing project needs with ease.</li>
                        </ul>
                    </ul>
                </Col>
                <Col>
                    <Card.Img src={Storyboard}/>
                    <p>Project Completed August 2021</p>
                </Col>
            </Row>
            <Row>
                <h3>Conclusion</h3>
                <p>The development of the collaborative project storyboarding platform has addressed the need for a lightweight and user-friendly solution for project collaboration and management. By providing users with a platform to effectively storyboard their projects and facilitating collaboration with freelancers through the integrated marketplace feature, the project has contributed to streamlining project workflows and enhancing productivity in software development environments. Moving forward, continued refinement and expansion of the platform will further solidify its role as a valuable tool for project coordination and collaboration in the software development industry.</p>
            </Row>
        </Container>
    )
}

export default Project3Card;