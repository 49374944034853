import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import Bittorrent from '../../../assets/img/portfolio/bittorrent.png'

const Project5Card = () => {
    return (
        <Container>
            <Row>
                <h3>Introduction</h3>
                <p>Initially conceived as an exploration of networks and their capabilities, this project aimed to create a clone of the infamous BitTorrent service. The objective was to develop a service capable of downloading media content by sourcing bits of it from a distributed network of data providers. To add an extra layer of challenge, the project was completed using the C programming language, known for its difficulty in handling network-related tasks compared to the more commonly used Python. Upon successfully downloading a movie, the project was considered a success, although it was not utilized further beyond this point. This report provides an overview of the project's objectives, methodology, and outcomes.</p>
            </Row>
            <Row>
                <Col>
                    <h3>Objectives</h3>
                    <ul>
                        <li>Explore the capabilities of networks and distributed systems through the development of a BitTorrent clone.</li>
                        <li>Challenge programming skills by implementing the project in the C programming language, known for its complexity in network-related tasks.</li>
                        <li>Successfully download media content from a distributed network using the developed BitTorrent clone.</li>
                    </ul>
                    <h3>Implementation</h3>
                    <ul>
                        <li><h4>BitTorrent Clone Development</h4></li>
                        <ul>
                            <li>Implemented the basic functionality of a BitTorrent client, including peer discovery, file downloading, and data sharing.</li>
                            <li>Developed algorithms for efficiently downloading media content by sourcing bits of data from multiple peers across the network.</li>
                            <li>Utilized socket programming and network protocols to establish connections and exchange data with peers in a distributed network environment.</li>
                        </ul>
                    </ul>
                    <h3>Outcomes</h3>
                    <ul>
                        <li><h4>Successful Media Content Download</h4></li>
                        <ul>
                            <li>Successfully developed a BitTorrent clone capable of downloading media content from a distributed network of data providers.</li>
                            <li>Demonstrated the feasibility of using the C programming language for implementing network-related tasks, despite its challenges compared to Python.</li>
                        </ul>
                        <li><h4>Technical Skill Enhancement</h4></li>
                        <ul>
                            <li>Enhanced programming skills, particularly in C programming and network programming concepts.</li>
                            <li>Gained practical experience in implementing complex network protocols and distributed systems.</li>
                        </ul>
                        <li><h4>Project Evaluation</h4></li>
                        <ul>
                            <li>Considered the project a success upon achieving the primary objective of downloading a movie using the developed BitTorrent clone.</li>
                            <li>Although the project was not utilized beyond this point, it served as a valuable learning experience in exploring networks, distributed systems, and programming challenges.</li>
                        </ul>
                    </ul>
                </Col>
                <Col>
                    <Card.Img src={Bittorrent}/>
                    <p>Project Completed December 2020</p>
                </Col>
            </Row>
            <Row>
                <h3>Conclusion</h3>
                <p>The development of the BitTorrent clone in C has provided valuable insights into network programming, distributed systems, and programming language challenges. Despite the complexity of implementing network-related tasks in C, the project demonstrated the feasibility of developing a functional BitTorrent client capable of downloading media content from a distributed network. Moving forward, the skills and knowledge gained from this project will serve as a solid foundation for tackling future challenges in network programming and distributed systems development.</p>
            </Row>
        </Container>
    )
}

export default Project5Card;