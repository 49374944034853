import React from 'react'
import { Container, Accordion } from 'react-bootstrap'

import Project1Card from './portFolioCards/project1card'
import Project2Card from './portFolioCards/project2card'
import Project3Card from './portFolioCards/project3card'
import Project4Card from './portFolioCards/project4card'
import Project5Card from './portFolioCards/project5card'
import Project6Card from './portFolioCards/project6card'

const Portfolio = () => {
    return (
        <>
            <section>
                <Container>
                    <h1 className='text-uppercase text-center text-secondary'>My Portfolio</h1>
                    <hr className='star-dark mb-0'/>
                    <h4 className='text-center text-primary'>Explore Some of My Personal and Professional Projects</h4>
                </Container>
            </section>
            <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header><div className='fw-bold'>Project River</div></Accordion.Header>
                    <Accordion.Body>
                        <Project1Card />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header><div className='fw-bold'>SafeHouse Digital</div></Accordion.Header>
                    <Accordion.Body>
                        <Project2Card />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header><div className='fw-bold'>Co-Design</div></Accordion.Header>
                    <Accordion.Body>
                        <Project3Card />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header><div className='fw-bold'>Virus AI</div></Accordion.Header>
                    <Accordion.Body>
                        <Project4Card />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='4'>
                    <Accordion.Header><div className='fw-bold'>BitTorrent Clone</div></Accordion.Header>
                    <Accordion.Body>
                        <Project5Card />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='5'>
                    <Accordion.Header><div className='fw-bold'>We-Park</div></Accordion.Header>
                    <Accordion.Body>
                        <Project6Card />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </>
    )
}

export default Portfolio;