import React, { useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'

import Resume from '../../../../assets/Maxim_Kapelina-Resume.pdf'

const AboutSummary = () => {
    const [show, setShow] = useState(false)

    const aboutSummary1 = "I am a diligent, reliable, and energetic STEM oriented application developer with a degree in Computer Science from the University of Maryland. I have experience in full stack and application development, as well as a thorough understanding of development principles. I am interested in Artificial intelligence, Robotics, Software Development, Mathematics, Finance, and Data Analytics."
    const aboutSummary2 = "I am not only a passionate developer, but also a versatile leader with a knack for creative problem solving. With a background deeply rooted in Computer Science, I bring a blend of technical experience with innovative thinking to every project I undertake. I've explored many diverse domains beyond development, such as graphic design, real estate development, and playing Piano."

    return (
        <section className='text-white bg-primary mb-0'>
            <Container>
                <h2 className='text-uppercase text-center text-white'>About Me</h2>
                <hr className='star-light mb-5'/>
                <Row>
                    <Col lg={4} className='ms-auto'>
                        <p className='lead'>{aboutSummary1}</p>
                    </Col>
                    <Col lg={4} className='me-auto'>
                        <p className='lead'>{aboutSummary2}</p>
                    </Col>
                </Row>
                <div className='text-center mt-4'>
                    <Button className='border btn-xl' onClick={() => setShow(true)}>
                        <i className='fa fa-download me-2'/>
                        <span>My Resume</span>
                    </Button>
                </div>
            </Container>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body className='text-center'>
                    Are you sure you want to download my Resume?
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <a href={Resume} download='Maxim_Kapelina-Resume.pdf'>
                        <Button className='btn-primary' onClick={() => setShow(false)}>Yes, Download</Button>
                    </a>
                    <Button className='btn-secondary' onClick={() => setShow(false)}>No, thanks</Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}


export default AboutSummary;