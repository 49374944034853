import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

const ContactForm = (props) => {
    const FORM_ENDPOINT = 'https://formspree.io/f/moqgwrre'

    const handleSubmit = () => {
        document.getElementById('contactForm').submit()
        document.getElementById('contactForm').reset()
    }

    return (
        <section>
            <Container>
                {props.isPage ? <>
                    <h1 className='text-uppercase text-center text-secondary mb-0'>Contact Me</h1>
                    <hr className='star-dark mb-0'/>
                    <h4 className='text-center text-primary'>I'll get back to you at the provided email or phone number</h4>
                </>:<>
                    <h2 className='text-uppercase text-center text-secondary mb-0'>Contact Me</h2>
                    <hr className='star-dark mb-5'/>
                </>}
                <Row>
                    <Col lg={8} className='mx-auto'>
                        <Form action={FORM_ENDPOINT} method='POST' id='contactForm'>
                            <Form.Group className='mb-0 form-floating controls pb-2'>
                                <Form.Control type='text' placeholder='Enter Name' name='name' required/>
                                <Form.Label>Your Name</Form.Label>
                                <Form.Text className='text-muted font-weight-light text-danger help-block'/>
                            </Form.Group>
                            <Form.Group className='mb-0 form-floating controls pb-2'>
                                <Form.Control type='email' placeholder='Enter Email' name='email' required />
                                <Form.Label>Email Address</Form.Label>
                                <Form.Text className='text-muted font-weight-light text-danger help-block'/>
                            </Form.Group>
                            <Form.Group className='mb-0 form-floating controls pb-2'>
                                <Form.Control type='tel' placeholder='Enter Phone Number' name='phone' required/>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Text className='text-muted font-weight-light text-danger help-block'/>
                            </Form.Group>
                            <Form.Group className='mb-0 form-floating controls pb-2'>
                                <Form.Control as='textarea' placeholder='Enter Message' name='message' required style={{'height':'100px'}}/>
                                <Form.Label>Message</Form.Label>
                                <Form.Text className='text-muted font-weight-light text-danger help-block'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control hidden readOnly name='_subject' value='Contact Form Submission'/>
                            </Form.Group>
                            <Button className='btn-primary btn-xl mt-2' onClick={ handleSubmit }>
                                Send Message
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ContactForm;