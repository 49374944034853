import React, { useState } from 'react'
import { Col, Modal, Row, Container, Image, Button } from 'react-bootstrap';

import River from '../../../../assets/img/portfolio/river.png'
import Safehouse from '../../../../assets/img/portfolio/safehouse.png'
import Storyboard from '../../../../assets/img/portfolio/storyboard.png'
import Coronavirus from '../../../../assets/img/portfolio/coronavirus.png'
import Bittorrent from '../../../../assets/img/portfolio/bittorrent.png'
import Wepark from '../../../../assets/img/portfolio/wepark.png'

import RiverCover from '../../../../assets/img/portfolio/river_cover.png'
import SafehouseCover from '../../../../assets/img/portfolio/safehouse_cover.png'
import CoDesignCover from '../../../../assets/img/portfolio/codesign_cover.png'
import VirusAICover from '../../../../assets/img/portfolio/virus_ai_cover.png'
import BittorrentCover from '../../../../assets/img/portfolio/bittorrent_cover.png'
import WeParkCover from '../../../../assets/img/portfolio/wepark_cover.png'

const PortfolioCardDeck = () => {
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)
    const [show5, setShow5] = useState(false)
    const [show6, setShow6] = useState(false)

    const RiverSummary = "This project was conceived to connect content creators with advertisers. Additionally, I sought to provide an alternative revenue stream to creators through product placement in their content. The platform I built would allow creators to “match” with advertisers. Then, those creators would be paid to place the advertiser’s product in the background of their content to promote brand awareness and boost sales."
    const SafeHouseSummary = "During the hype surrounding blockchain technology as an innovative way to secure digital, and potentially physical, assets, I wanted to explore if this was true. To do this, I created a blockchain capable of storing leases, deeds, and other necessary details associated with ownership of a real estate property. A user would come on to my site, upload any ownership documents, verify their identity, then receive a digitally verified proof of ownership, secured on a blockchain maintained by my backend application."
    const CoDesignSummary = "As someone who has used GitHub, Jira, Slack, and other professional organizational tools for software development, I wanted to find a way to make collaborating on projects easier and more lightweight. To do this, I created a platform, where users could effectively “storyboard” any technical project in a collaborative manner. Additionally, my platform provided the ability for users to post sections of their project to a marketplace where freelancers could bid to complete that project section and get paid for it."
    const VirusAISummary = "During the height of the Coronavirus Pandemic, I wanted to determine if an AI could be capable of simulating a virus which could infect a population. I hoped this exploration of AI capabilities would both expand my understand of AI while also providing some meaningful results on the efficacy of various viruses. To do this, I simulated a population, and provided a generative AI model with the “DNA” of a virus, where the AI could “learn” which DNA traits produced the most infectious and deadly virus."
    const BitTorrentSummary = "Initially done as an exploration of networks and their capabilities, I attempted to create a clone of the infamous BitTorrent service: a service capable of downloading any media content by sourcing bits of it from across a distributed network of data providers. To further challenge myself, I completed this project in C, which is a much harder language to use when performing any kind of network related tasks than the current standard of Python. After being able to successfully download a movie, I considered the project a success and certainly never used it again."
    const WeParkSummary = "While in my early college days, I was constantly frustrated by the lack of available, affordable parking on or around the campus. I did notice however, that although many residents had street parking passes associated with their leases, they were infrequently, or even never used. I thought, why can’t that person rent out their parking space on an as needed basis? To allow someone to do this, I created a simple app, which would show a user nearby parking spaces, and allow someone with an unused parking space to post it on our app and set the price per hour on that spot."

    return (
        <section>
            <Container>
                <h2 className='text-uppercase text-center text-secondary'>Portfolio</h2>
                <hr className='star-dark mb-5'/>
                <Row>
                    <Col md={6} lg={4} className='mb-2'>
                        <Button className='d-block mx-auto portfolio-item' onClick={() => setShow1(true)}>
                            <div className='d-flex portfolio-item-caption position-absolute'>
                                <div className='text-center my-auto portfolio-item-caption-content'>
                                    <i className='fa fa-search-plus fa-3x mx-1'/>
                                </div>
                            </div>
                            <Image src={RiverCover} width='390' height='280'/>
                        </Button>
                    </Col>
                    <Col md={6} lg={4} className='mb-2'>
                        <Button className='d-block mx-auto portfolio-item' onClick={() => setShow2(true)}>
                            <div className='d-flex portfolio-item-caption position-absolute'>
                                <div className='text-center my-auto portfolio-item-caption-content'>
                                    <i className='fa fa-search-plus fa-3x mx-1'/>
                                </div>
                            </div>
                            <Image src={SafehouseCover} width='390' height='280'/>
                        </Button>
                    </Col>
                    <Col md={6} lg={4} className='mb-2'>
                        <Button className='d-block mx-auto portfolio-item' onClick={() => setShow3(true)}>
                            <div className='d-flex portfolio-item-caption position-absolute'>
                                <div className='text-center my-auto portfolio-item-caption-content'>
                                    <i className='fa fa-search-plus fa-3x mx-1'/>
                                </div>
                            </div>
                            <Image src={CoDesignCover} width='390' height='280'/>
                        </Button>
                    </Col>
                    <Col md={6} lg={4} className='mb-2'>
                        <Button className='d-block mx-auto portfolio-item' onClick={() => setShow4(true)}>
                            <div className='d-flex portfolio-item-caption position-absolute'>
                                <div className='text-center my-auto portfolio-item-caption-content'>
                                    <i className='fa fa-search-plus fa-3x mx-1'/>
                                </div>
                            </div>
                            <Image src={VirusAICover} width='390' height='280'/>
                        </Button>
                    </Col>
                    <Col md={6} lg={4} className='mb-2'>
                        <Button className='d-block mx-auto portfolio-item' onClick={() => setShow5(true)}>
                            <div className='d-flex portfolio-item-caption position-absolute'>
                                <div className='text-center my-auto portfolio-item-caption-content'>
                                    <i className='fa fa-search-plus fa-3x mx-1'/>
                                </div>
                            </div>
                            <Image src={BittorrentCover} width='390' height='280'/>
                        </Button>
                    </Col>
                    <Col md={6} lg={4} className='mb-2'>
                        <Button className='d-block mx-auto portfolio-item' onClick={() => setShow6(true)}>
                            <div className='d-flex portfolio-item-caption position-absolute'>
                                <div className='text-center my-auto portfolio-item-caption-content'>
                                    <i className='fa fa-search-plus fa-3x mx-1'/>
                                </div>
                            </div>
                            <Image src={WeParkCover} width='390' height='280'/>
                        </Button>
                    </Col>
                </Row>
            </Container>
            
            {/* PROJECT 1 */}
            <Modal className='text-center' show={show1} onHide={() => setShow1(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <h2 className='text-uppercase text-secondary mb-0'>River</h2>
                    <hr className='star-dark mb-5'/>
                    <Image rounded fluid className='mb-5' src={River}/>
                    <p class="mb-5">{RiverSummary}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-primary btn-lg mx-auto rounded-pill portfolio-modal-dismiss' onClick={() => setShow1(false)} >
                        <i className='fa fa-close'/>
                        -Close Project
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* PROJECT 2 */}
            <Modal className='text-center' show={show2} onHide={() => setShow2(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <h2 className='text-uppercase text-secondary mb-0'>SafeHouse Digital</h2>
                    <hr className='star-dark mb-5'/>
                    <Image rounded fluid className='mb-5' src={Safehouse}/>
                    <p class="mb-5">{SafeHouseSummary}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-primary btn-lg mx-auto rounded-pill portfolio-modal-dismiss' onClick={() => setShow2(false)} >
                        <i className='fa fa-close'/>
                        -Close Project
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* PROJECT 3 */}
            <Modal className='text-center' show={show3} onHide={() => setShow3(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <h2 className='text-uppercase text-secondary mb-0'>Co-Design</h2>
                    <hr className='star-dark mb-5'/>
                    <Image rounded fluid className='mb-5' src={Storyboard}/>
                    <p class="mb-5">{CoDesignSummary}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-primary btn-lg mx-auto rounded-pill portfolio-modal-dismiss' onClick={() => setShow3(false)} >
                        <i className='fa fa-close'/>
                        -Close Project
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* PROJECT 4 */}
            <Modal className='text-center' show={show4} onHide={() => setShow4(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <h2 className='text-uppercase text-secondary mb-0'>Virus AI</h2>
                    <hr className='star-dark mb-5'/>
                    <Image rounded fluid className='mb-5' src={Coronavirus}/>
                    <p class="mb-5">{VirusAISummary}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-primary btn-lg mx-auto rounded-pill portfolio-modal-dismiss' onClick={() => setShow4(false)} >
                        <i className='fa fa-close'/>
                        -Close Project
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* PROJECT 5 */}
            <Modal className='text-center' show={show5} onHide={() => setShow5(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <h2 className='text-uppercase text-secondary mb-0'>BitTorrent Clone</h2>
                    <hr className='star-dark mb-5'/>
                    <Image rounded fluid className='mb-5' src={Bittorrent}/>
                    <p class="mb-5">{BitTorrentSummary}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-primary btn-lg mx-auto rounded-pill portfolio-modal-dismiss' onClick={() => setShow5(false)} >
                        <i className='fa fa-close'/>
                        -Close Project
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* PROJECT 6 */}
            <Modal className='text-center' show={show6} onHide={() => setShow6(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <h2 className='text-uppercase text-secondary mb-0'>WePark</h2>
                    <hr className='star-dark mb-5'/>
                    <Image rounded fluid className='mb-5' src={Wepark}/>
                    <p class="mb-5">{WeParkSummary}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-primary btn-lg mx-auto rounded-pill portfolio-modal-dismiss' onClick={() => setShow6(false)} >
                        <i className='fa fa-close'/>
                        -Close Project
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default PortfolioCardDeck;