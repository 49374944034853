import React from 'react'
import { Container, Nav, Navbar, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Favicon from '../../assets/img/favicon.png'

const Header = () => {
    return (
        <header>
            <HeaderNavBar />
        </header>
    )
}

const HeaderNavBar = () => {
    return (
        <Navbar sticky='top' className='navbar-expand-lg bg-secondary text-uppercase navbar-light'>
            <Container className='text-white'>
                <Navbar.Brand href='/' className='text-white'>
                    <Image fluid src={Favicon} />
                    &nbsp;Maxim
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse>
                    <Nav className='ms-auto'>
                        <Nav.Link as={Link} to='/portfolio' className='text-white'>Portfolio</Nav.Link>
                        <Nav.Link as={Link} to='/about' className='text-white'>About</Nav.Link>
                        <Nav.Link as={Link} to='/contact' className='text-white'>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;