import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'

import Safehouse from '../../../assets/img/portfolio/safehouse.png'

const Project2Card = () => {
    return (
        <Container>
            <Row>
                <h3>Introduction</h3>
                <p>Amidst the buzz surrounding blockchain technology's potential to revolutionize asset security, particularly in the realm of real estate ownership, this project was initiated to investigate the feasibility of leveraging blockchain for this purpose. The project aimed to develop a blockchain system capable of securely storing ownership documents, such as leases and deeds, and providing users with digitally verified proofs of ownership. This report outlines the objectives, implementation strategies, and outcomes of the project.</p>
            </Row>
            <Row>
                <Col>
                    <h3>Objectives</h3>
                    <ul>
                        <li>Explore the viability of blockchain technology for securely storing real estate ownership documents.</li>
                        <li>Develop a blockchain-based system capable of securely storing and verifying ownership documents.</li>
                        <li>Provide users with digitally verified proofs of ownership for real estate properties.</li>
                    </ul>
                    <h3>Implementation</h3>
                    <ul>
                        <li><h4>Blockchain Development</h4></li>
                        <ul>
                            <li>Created a blockchain network capable of securely storing ownership documents associated with real estate properties.</li>
                            <li>Implemented smart contracts to manage the uploading, verification, and storage of ownership documents on the blockchain.</li>
                            <li>Developed a decentralized application (DApp) interface to facilitate user interaction with the blockchain network.</li>
                        </ul>
                        <li><h4>User Verification System</h4></li>
                        <ul>
                            <li>Integrated a user verification system to ensure the authenticity of users uploading ownership documents.</li>
                            <li>Implemented identity verification procedures to authenticate users before granting access to upload documents.</li>
                            <li>Utilized cryptographic techniques to securely link users' identities with their uploaded documents on the blockchain.</li>
                        </ul>
                    </ul>
                    <h3>Outcomes</h3>
                    <ul>
                        <li><h4>Secure Ownership Document Storage</h4></li>
                        <ul>
                            <li>Successfully developed a blockchain system capable of securely storing leases, deeds, and other ownership documents associated with real estate properties.</li>
                            <li>Ensured the integrity and immutability of ownership records stored on the blockchain through cryptographic techniques and decentralized consensus mechanisms.</li>
                        </ul>
                        <li><h4>Digitally Verified Proofs of Ownership</h4></li>
                        <ul>
                            <li>Provided users with digitally verified proofs of ownership for real estate properties uploaded to the blockchain.</li>
                            <li>Enabled users to easily verify their ownership status and share proof of ownership with relevant parties, such as landlords, tenants, or legal authorities.</li>
                        </ul>
                        <li><h4>Enhanced Security and Transparency</h4></li>
                        <ul>
                            <li>Enhanced security and transparency in real estate transactions by leveraging blockchain technology to streamline ownership verification processes.</li>
                            <li>Contributed to the adoption of blockchain technology in the real estate industry, paving the way for more efficient and secure property transactions.</li>
                        </ul>
                    </ul>
                </Col>
                <Col>
                    <Card.Img src={Safehouse}/>
                    <p>Project Completed November 2022</p>
                </Col>
            </Row>
            <Row>
                <h3>Conclusion</h3>
                <p>The development of a blockchain-based system for real estate ownership verification has demonstrated the potential of blockchain technology to revolutionize the way ownership documents are stored and verified. By providing users with digitally verified proofs of ownership and enhancing security and transparency in real estate transactions, the project has laid the groundwork for the adoption of blockchain technology in the real estate industry. Moving forward, continued refinement and expansion of the system will further solidify its role as a trusted solution for real estate ownership verification in the digital age.</p>
            </Row>
        </Container>
    )
}

export default Project2Card;