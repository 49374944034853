import React from 'react'

import Landing from './subcomponents/landing';
import PortfolioCardDeck from './subcomponents/portfolioCardDeck';
import AboutSummary from './subcomponents/aboutSummary';
import ContactForm from './subcomponents/contactForm';

const Home = () => {
    return (
        <>
            <Landing />
            <PortfolioCardDeck />
            <AboutSummary />
            <ContactForm />
        </>
    )
}

export default Home;